import type { VFC } from 'react'

import { useWindowSize } from '../../utils'
// import Notifications from './Notifications'
// import SearchInput from './SearchInput'
import User from './User'

const Header: VFC = () => {
  const [width] = useWindowSize()

  return (
    <div className="topbar">
      <ul className="flex items-center m-0 pl-6 gap-4">
        <li className={`${width >= 992 ? 'block' : 'hidden'}`} />
        {/* <li>
          <SearchInput />
        </li> */}
      </ul>
      <ul className="flex items-center m-0 pr-6 gap-2">
        {/* <li>
          <Notifications />
        </li> */}
        <li>
          <User />
        </li>
      </ul>
    </div>
  )
}

export default Header
