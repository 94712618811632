import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'

import type {
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type {
  Order,
  OrderCreateValue,
  OrderQueryParams,
  OrderUpdateValue,
} from './types/orders.types'

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/orders`,
  }),
  tagTypes: ['Orders'],
  endpoints: builder => ({
    ordersList: builder.query<
      CommonPaginationAPIResults<Order>,
      CommonPaginationAPIRequest<OrderQueryParams> | void
    >({
      query: params => {
        const orderStatus = params ? params.status : null
        let formattedOrderStatus = ''
        if (orderStatus) {
          if (Array.isArray(orderStatus)) {
            formattedOrderStatus = orderStatus.join(',')
          } else formattedOrderStatus = orderStatus.toString()
        }
        return {
          url: `/`,
          method: 'GET',
          params: params && {
            ...omit('pagination', {
              ...params,
              status: formattedOrderStatus || undefined,
            }),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: [{ type: 'Orders', id: 'ORDERS_LIST' }],
    }),
    orderDetails: builder.query<Order, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Orders', id: 'DETAILS' }],
    }),
    createOrder: builder.mutation<Order, OrderCreateValue>({
      query: newOrder => ({
        url: `/`,
        method: 'POST',
        data: newOrder,
      }),
      invalidatesTags: [
        { type: 'Orders', id: 'ORDERS_LIST' },
        { type: 'Orders', id: 'DETAILS' },
      ],
    }),
    updateOrder: builder.mutation<Order, CommonUpdateRequest<OrderUpdateValue>>(
      {
        query: ({
          EntityToUpdate: updatedOrder,
          EntityIdToUpdate: orderToUpdateId,
        }) => ({
          url: `/${orderToUpdateId}/`,
          method: 'PUT',
          data: updatedOrder,
        }),
        invalidatesTags: [
          { type: 'Orders', id: 'ORDERS_LIST' },
          { type: 'Orders', id: 'DETAILS' },
        ],
      }
    ),
    deleteOrder: builder.mutation<Order, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Orders', id: 'ORDERS_LIST' },
        { type: 'Orders', id: 'DETAILS' },
      ],
    }),
    createReport: builder.mutation<void, string>({
      query: id => ({
        url: `/${id}/reports/`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useOrdersListQuery,
  useLazyOrdersListQuery,
  useOrderDetailsQuery,
  useLazyOrderDetailsQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useCreateReportMutation,
} = ordersApi
