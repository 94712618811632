import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'

import type { DashboardResults } from './types/dashboard.types'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/dashboard`,
  }),
  tagTypes: ['Dashboard'],
  endpoints: builder => ({
    dashboardResults: builder.query<DashboardResults, void>({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
      providesTags: [{ type: 'Dashboard', id: 'DASHBOARD_RESULTS' }],
    }),
  }),
})

export const { useDashboardResultsQuery } = dashboardApi
