import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
  // CommonUpdateRequest,
} from '../types/common.types'
import type {
  OrderReviews,
  OrderReviewsCreate,
} from '../types/reviews/order-reviews.types'

export const ordersReviewsApi = createApi({
  reducerPath: 'ordersReviewsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/orders-reviews`,
  }),
  tagTypes: ['OrdersReview'],
  endpoints: builder => ({
    ordersReviewList: builder.query<
      CommonPaginationAPIResults<OrderReviews>,
      CommonPaginationAPIRequest | undefined
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'OrdersReview', id: 'ORDER_REVIEWS_LIST' }],
    }),
    orderReviewDetails: builder.query<OrderReviews, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'OrdersReview', id: 'DETAILS' }],
    }),
    createOrderReview: builder.mutation<OrderReviews, OrderReviewsCreate>({
      query: newOrderReview => ({
        url: '/',
        method: 'POST',
        data: newOrderReview,
      }),
      invalidatesTags: [
        { type: 'OrdersReview', id: 'ORDER_REVIEWS_LIST' },
        { type: 'OrdersReview', id: 'DETAILS' },
      ],
    }),
    updateOrdersReviewStatus: builder.mutation<
      OrderReviews,
      CommonUpdateRequest<{
        status: OrderReviews['status']
      }>
    >({
      query: ({
        EntityToUpdate: updatedOrderReview,
        EntityIdToUpdate: OrderReviewToUpdateId,
      }) => ({
        url: `/${OrderReviewToUpdateId}/`,
        method: 'PUT',
        data: updatedOrderReview,
      }),
      invalidatesTags: [
        { type: 'OrdersReview', id: 'ORDER_REVIEWS_LIST' },
        { type: 'OrdersReview', id: 'DETAILS' },
      ],
    }),
    rejectOrCancelOrderReview: builder.mutation<CommonDeleteResponse, string>({
      query: orderReviewId => ({
        url: `/${orderReviewId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'OrdersReview', id: 'ORDER_REVIEWS_LIST' },
        { type: 'OrdersReview', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useOrdersReviewListQuery,
  useLazyOrdersReviewListQuery,
  useOrderReviewDetailsQuery,
  useCreateOrderReviewMutation,
  useUpdateOrdersReviewStatusMutation,
  useRejectOrCancelOrderReviewMutation,
} = ordersReviewsApi
