export const concatSymbols = (symbols: (string | symbol)[]): string => {
  let concatenation = ''
  symbols.forEach(symbol => {
    if (symbol) {
      concatenation = concatenation.concat(
        typeof symbol === 'string' ? symbol : symbol.description ?? ''
      )
    }
  })

  return concatenation
}
