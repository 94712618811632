import { createApi } from '@reduxjs/toolkit/query/react'

import Alerts from '@breve/components/Alerts'
import type { EmployeeRequest } from '@breve/services/types/employee.types'
import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'

import type { ChangePasswordRequest, UserProfile } from './types/auth.types'
import type { RTKQueryRawErrorResponse } from './types/errors.types'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/auth`,
  }),
  tagTypes: ['CurrentUser'],
  endpoints: builder => ({
    fetchUser: builder.query<UserProfile, void>({
      query: () => {
        return {
          url: '/current-user',
          method: 'GET',
        }
      },
      providesTags: ['CurrentUser'],
    }),
    selfUpdateUser: builder.mutation<UserProfile, EmployeeRequest>({
      query: user => ({
        url: '/current-user',
        method: 'PUT',
        data: user,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await Alerts.loading('Actualizando información')
        try {
          const { data: user } = await queryFulfilled
          // `onSuccess` side-effect
          if (user) {
            await Alerts.success(
              'Sus datos han sido actualizados correctamente'
            )
          }
        } catch ({ error }) {
          // `onError` side-effect
          const { data } = error as RTKQueryRawErrorResponse
          await Alerts.apiError(data)
        }
      },
      invalidatesTags: ['CurrentUser'],
    }),
    selfUpdateUserPassword: builder.mutation<
      UserProfile,
      ChangePasswordRequest
    >({
      query: newPasswordRequest => ({
        url: '/current-user',
        method: 'PATCH',
        data: newPasswordRequest,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await Alerts.loading('Actualizando información')
        try {
          const { data: user } = await queryFulfilled
          // `onSuccess` side-effect
          if (user) {
            await Alerts.success(
              'Sus datos han sido actualizados correctamente'
            )
          }
        } catch ({ error }) {
          // `onError` side-effect
          const { data } = error as RTKQueryRawErrorResponse
          await Alerts.apiError(data)
        }
      },
    }),
  }),
})

export const {
  useFetchUserQuery,
  useSelfUpdateUserMutation,
  useSelfUpdateUserPasswordMutation,
} = authApi
