import { message } from 'antd'
import type { MessageType } from 'antd/es/message/interface'

import type { ApiError } from '@breve/services/types/errors.types'

const Alerts = {
  info: (msg: string): MessageType => message.info(msg),
  error: (msg = 'Ha ocurrido un error'): MessageType => message.error(msg),
  warning: (msg: string): MessageType => message.warning(msg),
  success: (msg = 'Operación exitosa'): MessageType => message.success(msg),
  loading: (msg = 'Cargando'): MessageType => message.loading(msg),
  apiError: async (errorObject: ApiError): Promise<void> => {
    // if (errorObject.message) await message.error(errorObject.message)
    // else if (errorObject.errors && errorObject.errors.length > 0) {
    //   // eslint-disable-next-line no-console
    //   console.log(errorObject.errors)
    //   // errorObject.errors &&
    //   //   Promise.allSettled(
    //   //     errorObject.errors.map(async err => await message.error(err))
    //   //   )
    // } else
    //   await message.error(
    //     'Ha ocurrido un error no controlado. Contactar a soporte'
    //   )

    const { message: errorMessage, errors, detail: errorDetail } = errorObject

    // eslint-disable-next-line no-console
    // console.log(`Error Object: ${JSON.stringify(errorObject)}`)
    if (errors && errors.length > 0) {
      // eslint-disable-next-line no-console
      console.log(errors)
      errors.map(async err => message.error(err))
    } else if (errorMessage) {
      await message.error(errorMessage)
    } else if (errorDetail) {
      await message.error(errorDetail)
    } else {
      await message.error(
        'Ha ocurrido un error no controlado. Contactar a soporte'
      )
    }
  },
}
export default Alerts
