import { configureStore } from '@reduxjs/toolkit'

import { authApi } from '@breve/services/auth.services'
import { companiesApi } from '@breve/services/companies.services'
import { dashboardApi } from '@breve/services/dashboard'
import { employeesApi } from '@breve/services/employees.services'
import { enumsApi } from '@breve/services/enums.services'
import { examsApi } from '@breve/services/exams.services'
import { examComponentsApi } from '@breve/services/exams-components.services'
import { examsProfilesApi } from '@breve/services/exams-profiles.services'
import {
  examsAssignToFieldsApi,
  fieldsApi,
} from '@breve/services/fields.services'
import { companyOrdersApi } from '@breve/services/orders/company_orders.services'
import { patientsApi } from '@breve/services/patient.services'
import { profilesApi } from '@breve/services/profiles.services'
import { resultsApi } from '@breve/services/results.services'
import { examResultsReviewsApi } from '@breve/services/reviews/exam-result-reviews.services'
import { companyOrdersReviewsApi } from '@breve/services/reviews/order-company-reviews.services'
import { ordersReviewsApi } from '@breve/services/reviews/order-reviews.services'

import { ordersApi } from '../../services/orders.services'
import alertsReducer from './alertsSlice'
import authReducer from './authSlice'
import patientReducer from './patientSlice'

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [enumsApi.reducerPath]: enumsApi.reducer,
    [fieldsApi.reducerPath]: fieldsApi.reducer,
    [examsAssignToFieldsApi.reducerPath]: examsAssignToFieldsApi.reducer,
    [examsApi.reducerPath]: examsApi.reducer,
    [examsProfilesApi.reducerPath]: examsProfilesApi.reducer,
    [companyOrdersApi.reducerPath]: companyOrdersApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [profilesApi.reducerPath]: profilesApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
    [examComponentsApi.reducerPath]: examComponentsApi.reducer,
    [examResultsReviewsApi.reducerPath]: examResultsReviewsApi.reducer,
    [companyOrdersReviewsApi.reducerPath]: companyOrdersReviewsApi.reducer,
    [ordersReviewsApi.reducerPath]: ordersReviewsApi.reducer,
    auth: authReducer,
    patient: patientReducer,
    alerts: alertsReducer,
  },
  // devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      companiesApi.middleware,
      dashboardApi.middleware,
      patientsApi.middleware,
      employeesApi.middleware,
      enumsApi.middleware,
      fieldsApi.middleware,
      examsAssignToFieldsApi.middleware,
      examsApi.middleware,
      examsProfilesApi.middleware,
      companyOrdersApi.middleware,
      ordersApi.middleware,
      profilesApi.middleware,
      resultsApi.middleware,
      examComponentsApi.middleware,
      examResultsReviewsApi.middleware,
      companyOrdersReviewsApi.middleware,
      ordersReviewsApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
