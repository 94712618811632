import type { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@breve/hooks/auth/useAuth'

const AdminProtectedRoute: FC = ({ children }) => {
  const location = useLocation()
  const { user } = useAuth()

  const match = user?.user.groups.find(group => group.name === 'ADMINISTRATOR')

  return match?.name === 'ADMINISTRATOR' ? (
    <div>{children}</div>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  )
}

export default AdminProtectedRoute
