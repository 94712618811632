import type { FC } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
// antd
import { Button, Image, Typography } from 'antd'
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'

import logo from '@breve/assets/images/labsys/whiteLogo.ico'
import { authApi, useFetchUserQuery } from '@breve/services/auth.services'
import { logout, setIsLoggingOut } from '@breve/store/redux/authSlice'
import { store } from '@breve/store/redux/store'

const { Text } = Typography

type UserDropdownItem = {
  icon: JSX.Element
  label: string
  buttonType?: 'link' | 'text' | 'default' | 'primary' | 'dashed'
  action?: (navigate: NavigateFunction) => void
}

const options: UserDropdownItem[] = [
  {
    icon: <UserOutlined />,
    label: 'Perfil',
    buttonType: 'link',
    action: navigate => navigate('user-settings?action=details'),
  },
  {
    icon: <SettingOutlined />,
    label: 'Editar perfil',
    action: navigate => navigate('user-settings?action=edit'),
    buttonType: 'link',
  },
]

const UserContent: FC = () => {
  const handleLogout = (): void => {
    store.dispatch(setIsLoggingOut()) as unknown as void
    store.dispatch(logout()) as unknown as void
    store.dispatch(authApi.util.resetApiState())
  }
  const navigate = useNavigate()

  return (
    <ul className="-mx-4 my-0 sm:w-72">
      {options.length &&
        options.map(({ icon, label, buttonType, action }) => (
          <Button
            key={label}
            block
            type={buttonType ?? 'text'}
            className="text-left text-gray-500 py-2 flex items-center hover:bg-cyan-50 duration-75"
            icon={icon}
            onClick={action ? (): void => action(navigate) : undefined}
          >
            {label}
          </Button>
        ))}
      <Button
        block
        type="text"
        className="text-left text-gray-500 py-2 flex items-center hover:bg-cyan-50 duration-75"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
      >
        Cerrar Sesión
      </Button>
    </ul>
  )
}

const UserTitle: FC = () => {
  const { data } = useFetchUserQuery()
  const defineUserCivilNames = (): string => {
    if (data === undefined || data === null) {
      return 'John Doe'
    }

    return `${data.firstName} ${data.firstSurname}`
  }

  return (
    <div className="flex items-center -mx-4 -my-1 py-3 px-4 bg-secondary">
      {data?.profilePhoto ? (
        <Image
          src={data.profilePhoto}
          alt="Profile photo"
          className="w-9 rounded-full"
        />
      ) : (
        <div className="bg-white p-2 rounded-full">
          <img
            src={logo}
            alt="Labsys logo"
            className="w-5 h-5 rounded-md object-contain"
          />
        </div>
      )}
      <Text className="text-base text-white font-normal ml-3">
        {defineUserCivilNames()}
      </Text>
    </div>
  )
}
export { UserContent, UserTitle }
