import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { ExamRequest } from '@breve/validations'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type {
  Exam,
  ExamsListQueryParams,
  ExamWithReferenceFields,
} from './types/exams.types'

export const examsApi = createApi({
  reducerPath: 'examsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/catalogs/exams`,
  }),
  tagTypes: ['Exam'],
  endpoints: builder => ({
    examsList: builder.query<
      CommonPaginationAPIResults<Exam>,
      CommonPaginationAPIRequest<ExamsListQueryParams> | void
    >({
      query: params => {
        return {
          url: `/`,
          method: 'GET',
          params: params && {
            ...omit('pagination', params),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: [{ type: 'Exam', id: 'EXAM_LIST' }],
    }),
    examDetails: builder.query<ExamWithReferenceFields, Exam['id']>({
      query: id => {
        return {
          url: `/${id}/`,
          method: 'GET',
        }
      },
      providesTags: [{ type: 'Exam', id: 'EXAM_DETAILS' }],
    }),
    examUpdate: builder.mutation<
      ExamWithReferenceFields,
      CommonUpdateRequest<ExamRequest>
    >({
      query: ({ EntityIdToUpdate, EntityToUpdate }) => ({
        url: `/${EntityIdToUpdate}/`,
        method: 'PUT',
        data: EntityToUpdate,
      }),
      invalidatesTags: [
        { type: 'Exam', id: 'EXAM_LIST' },
        { type: 'Exam', id: 'EXAM_DETAILS' },
      ],
    }),
    changeExamActive: builder.mutation<CommonDeleteResponse, Exam['id']>({
      query: id => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Exam', id: 'EXAM_LIST' },
        { type: 'Exam', id: 'EXAM_DETAILS' },
      ],
    }),
    createExam: builder.mutation<ExamWithReferenceFields, ExamRequest>({
      query: newExam => ({
        url: '/',
        method: 'POST',
        data: newExam,
      }),
      invalidatesTags: [{ type: 'Exam', id: 'EXAM_LIST' }],
    }),
  }),
})

export const {
  useExamsListQuery,
  useLazyExamsListQuery,
  useExamDetailsQuery,
  useLazyExamDetailsQuery,
  useExamUpdateMutation,
  useChangeExamActiveMutation,
  useCreateExamMutation,
} = examsApi
