import { type FC } from 'react'
import { Suspense } from 'react'

import Spinner from '@breve/components/Spinner'

interface SuspenseHOCProps {
  view: JSX.Element
  fallback?: NonNullable<React.ReactNode>
}

const SuspenseHOC: FC<SuspenseHOCProps> = props => {
  const { view, fallback = null } = props
  return (
    <Suspense
      fallback={
        fallback ?? (
          <div className="flex justify-center items-center h-full">
            <Spinner snake size="large" />
          </div>
        )
      }
    >
      {view}
    </Suspense>
  )
}

export default SuspenseHOC
