import type { VFC } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface SpinnerProps {
  size: 'small' | 'large' | 'default' | undefined
  spinning?: boolean
  tip?: Node
  snake?: boolean
  className?: string
}

const Spinner: VFC<SpinnerProps> = props => {
  const { size, spinning, tip, snake, className } = props

  const indicator = snake ? (
    <LoadingOutlined spin className={className} />
  ) : undefined

  return (
    <Spin
      size={size}
      spinning={spinning}
      tip={tip}
      indicator={indicator}
      className={className}
    />
  )
}

export default Spinner
