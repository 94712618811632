import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'

import { ordersApi } from './orders.services'
import type { Result, ResultResponse } from './types/results.types'

export const resultsApi = createApi({
  reducerPath: 'resultsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/results`,
  }),
  tagTypes: ['Result'],
  endpoints: builder => ({
    createResult: builder.mutation<ResultResponse, Result>({
      query: newResult => ({
        url: '/',
        method: 'POST',
        data: newResult,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(ordersApi.util.invalidateTags(['Orders']))
      },
    }),
  }),
})

export const { useCreateResultMutation } = resultsApi
