import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'

import type {
  CommonDeleteResponse,
  // CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
  // CommonUpdateRequest,
} from '../types/common.types'
import type {
  ExamResultReviewCreate,
  ExamResultReviews,
} from '../types/reviews/exam-result-reviews.types'

export const examResultsReviewsApi = createApi({
  reducerPath: 'examResultsReviewsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/exam-results-reviews`,
  }),
  tagTypes: ['ExamResultReviews'],
  endpoints: builder => ({
    examResultReviewsList: builder.query<
      CommonPaginationAPIResults<ExamResultReviews>,
      CommonPaginationAPIRequest | undefined
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [
        { type: 'ExamResultReviews', id: 'EXAM_RESULT_REVIEWS_LIST' },
      ],
    }),
    examResultReviewDetails: builder.query<ExamResultReviews, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ExamResultReviews', id: 'DETAILS' }],
    }),
    createExamResultReview: builder.mutation<
      ExamResultReviews,
      ExamResultReviewCreate
    >({
      query: newExamResultReview => ({
        url: '/',
        method: 'POST',
        data: newExamResultReview,
      }),
      invalidatesTags: [
        { type: 'ExamResultReviews', id: 'EXAM_RESULT_REVIEWS_LIST' },
        { type: 'ExamResultReviews', id: 'DETAILS' },
      ],
    }),
    updateExamResultReviewStatus: builder.mutation<
      ExamResultReviews,
      CommonUpdateRequest<{
        status: ExamResultReviews['status']
      }>
    >({
      query: ({
        EntityToUpdate: updatedExamResultReview,
        EntityIdToUpdate: examResultReviewToUpdateId,
      }) => ({
        url: `/${examResultReviewToUpdateId}/`,
        method: 'PUT',
        data: updatedExamResultReview,
      }),
      invalidatesTags: [
        { type: 'ExamResultReviews', id: 'EXAM_RESULT_REVIEWS_LIST' },
        { type: 'ExamResultReviews', id: 'DETAILS' },
      ],
    }),
    rejectOrCancelExamResultReview: builder.mutation<
      CommonDeleteResponse,
      string
    >({
      query: examResultReviewId => ({
        url: `/${examResultReviewId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ExamResultReviews', id: 'EXAM_RESULT_REVIEWS_LIST' },
        { type: 'ExamResultReviews', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useExamResultReviewsListQuery,
  useLazyExamResultReviewsListQuery,
  useExamResultReviewDetailsQuery,
  useCreateExamResultReviewMutation,
  useUpdateExamResultReviewStatusMutation,
  useRejectOrCancelExamResultReviewMutation,
} = examResultsReviewsApi
