/**
 * paths.js
 * @module routes
 * @version 0.1.0
 * @author [Luisangel Marcia](https://github.com/CosmicTiger)
 * @file A utility file for storing the collection paths in a modular
 * approach in this project.
 */

/**
 * @author [Luisangel Marcia](https://github.com/CosmicTiger)
 * @see See [What is Object.freeze?](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze)
 * @see See [Why use Object.freeze?]()
 * @see See [Using enum with ES6](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/enum)
 * @see See [Using Symbol in enum](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol)
 * @readonly
 * @enum {object}
 * @const {object} PATHS_COLLECTIONS - This will going to collect as symbols all the possible routes
 * in the application and with concatenation the path for a route will be generated for React Router.
 */
const PATHS_COLLECTIONS = Object.freeze({
  PRIVATE_PATHS: {
    PRIVATE_BASE_PATH: Symbol('app/'),
    USER_PATHS: {
      BASE_PATH: Symbol('user-settings/'),
    },
    EXAMPLE_PATHS: {
      CHARTJS: Symbol('chartjs/'),
      TABLES: Symbol('tables/'),
    },
    COMPANIES_PATHS: {
      BASE_PATH: Symbol('companies/'),
      DETAILS_PATH: Symbol('company-details/'),
    },
    PATIENTS_PATHS: {
      BASE_PATH: Symbol('patients/'),
      DETAILS_PATH: Symbol('patient-details/'),
      EDIT: Symbol('patient-edit/'),
    },
    EMPLOYEES_PATHS: {
      BASE_PATH: Symbol('employees/'),
      DETAILS_PATH: Symbol('employee-details/'),
    },
    EXAMS_PATHS: {
      BASE_PATH: Symbol('exams/'),
      CREATE_PATH: Symbol('create/'),
      FIELDS_PATH: {
        BASE_PATH: Symbol('fields/'),
        DETAILS_PATH: Symbol('fields-specifications/'),
      },
      SETTINGS_PATH: {
        BASE_PATH: Symbol('settings/'),
      },
      COMPONENTS_PATH: {
        BASE_PATH: Symbol('components/'),
        DETAILS_PATH: Symbol('component-details/'),
      },
      RESULTS_PATH: {
        BASE_BATH: Symbol('create-result/'),
        REVIEW_BASE_PATH: Symbol('result/'),
      },
      PROFILES_PATH: {
        BASE_PATH: Symbol('profiles/'),
        DETAILS_PATH: Symbol('profile-details/'),
      },
    },
    ORDERS_PATH: {
      BASE_PATH: Symbol('orders/'),
      DETAILS_PATH: Symbol('order-details/'),
    },
    REVIEWS_PATH: {
      BASE_PATH: Symbol('reviews/'),
      DETAILS_PATH: Symbol('review-details/'),
    },
  },
  PUBLIC_PATHS: {
    BASE: Symbol('/'),
    SUCCESS: Symbol('success/'),
    FAILURE: Symbol('failure/'),
    DYNAMIC_IDS: Symbol(':id/'),
    USER_PATHS: {
      LOGIN: Symbol('login/'),
      REGISTER: Symbol('register/'),
      FORGOT_PASSWORD: Symbol('forgot-password/'),
    },
    CLIENT_ERRORS_PATHS: {
      NOT_FOUND: Symbol('*'),
      INTERNAL_SERVER_ERROR: Symbol('500/'),
    },
  },
})

export default PATHS_COLLECTIONS
