import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import AdminProtectedRoute from '@breve/components/AdminProtectedRoute'
import Spinner from '@breve/components/Spinner'
import SuspenseHOC from '@breve/components/SuspenseHOC'
import { AdminLayout } from '@breve/layout/AdminLayout'
import ConfigurationLayoutProvider from '@breve/store/context/ConfigurationLayout.context'

import {
  PAGE_NOT_FOUND,
  PRIVATE_BASE,
  PRIVATE_COMPANIES,
  PRIVATE_COMPANY_DETAILS,
  PRIVATE_EMPLOYEE_DETAILS,
  PRIVATE_EMPLOYEES,
  PRIVATE_EXAM_COMPONENTS,
  PRIVATE_EXAM_COMPONENTS_DETAILS,
  PRIVATE_EXAMS,
  PRIVATE_EXAMS_FIELDS,
  PRIVATE_EXAMS_FIELDS_CREATION,
  PRIVATE_EXAMS_FIELDS_DETAILS,
  PRIVATE_EXAMS_PROFILES,
  PRIVATE_EXAMS_PROFILES_DETAILS,
  PRIVATE_EXAMS_SETTINGS,
  PRIVATE_EXAMS_SETTINGS_BY_ID,
  PRIVATE_EXAMS_SETTINGS_CREATION,
  PRIVATE_ORDERS,
  PRIVATE_ORDERS_COMPANY,
  PRIVATE_ORDERS_COMPANY_DETAILS,
  PRIVATE_ORDERS_PATIENTS,
  PRIVATE_ORDERS_PATIENTS_DETAILS,
  PRIVATE_PATIENT_EDIT,
  PRIVATE_PATIENTS,
  PRIVATE_PATIENTS_DETAILS,
  PRIVATE_RESULTS,
  PRIVATE_RESULTS_BY_EXAM,
  PRIVATE_REVIEWS,
  PRIVATE_REVIEWS_EXAMS_RESULTS,
  PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS,
  PRIVATE_REVIEWS_ORDERS,
  PRIVATE_REVIEWS_ORDERS_DETAILS,
  // PRIVATE_REVIEWS_ORDERS_DETAILS,
  PRIVATE_USER_SETTINGS,
  PUBLIC_LOGIN,
  SERVER_ERROR,
} from './route-collection'

const Home = lazy(() => import('@breve/views/Home'))
const Login = lazy(() => import('@breve/views/Login'))
const Error404 = lazy(() => import('@breve/views/Error404'))
const Error500 = lazy(() => import('@breve/views/Error500'))
const Patients = lazy(() => import('@breve/views/patients/Patients'))
const PatientDetailsAndEdit = lazy(() => import('@breve/views/patients/_id'))
const Companies = lazy(() => import('@breve/views/company/Companies'))
const CompanyDetails = lazy(() => import('@breve/views/company/_id'))
const Employees = lazy(() => import('@breve/views/employees/Employees'))
const EmployeeDetails = lazy(() => import('@breve/views/employees/_id'))
const UserSettings = lazy(() => import('@breve/views/user-settings'))
const ExamsFieldsConf = lazy(() => import('@breve/views/exams/fields/Fields'))
const ExamsFieldCreation = lazy(
  () => import('@breve/views/exams/fields/FieldCreation')
)
const ExamsFieldsConfDetails = lazy(
  () => import('@breve/views/exams/fields/_id')
)
const ExamsSettings = lazy(
  () => import('@breve/views/exams/settings/ExamsSettings')
)
const ExamsSettingsById = lazy(() => import('@breve/views/exams/settings/_id'))
const ExamCreation = lazy(
  () => import('@breve/views/exams/settings/ExamCreation')
)
const Results = lazy(() => import('@breve/views/exams/results/ResultCreation'))
const ResultSecondStep = lazy(
  () => import('@breve/views/exams/results/ResultSecondStep')
)
const Orders = lazy(() => import('@breve/views/orders/Orders'))
const OrdersCompanies = lazy(
  () => import('@breve/views/orders/company/OrdersCompanies')
)
const OrdersCompaniesDetails = lazy(
  () => import('@breve/views/orders/company/_id')
)
const OrdersPatients = lazy(
  () => import('@breve/views/orders/individual/Orders')
)
const OrdersPatientsDetails = lazy(
  () => import('@breve/views/orders/individual/_id')
)

const ExamProfiles = lazy(
  () => import('@breve/views/exams/profile/ExamProfiles')
)
const ExamProfilesDetails = lazy(() => import('@breve/views/exams/profile/_id'))
const ExamComponents = lazy(
  () => import('@breve/views/exams/exam_components/ExamComponents')
)
const ExamComponentDetails = lazy(
  () => import('@breve/views/exams/exam_components/_id')
)

const Reviews = lazy(() => import('@breve/views/reviews/Reviews'))
const OrdersReviews = lazy(
  () => import('@breve/views/reviews/orders/OrdersReviews')
)
const OrdersReviewsDetails = lazy(
  () => import('@breve/views/reviews/orders/_id')
)
const ExamResultsReviews = lazy(
  () => import('@breve/views/reviews/exams-result/ExamResultReviews')
)
const ExamResultsReviewsDetails = lazy(
  () => import('@breve/views/reviews/exams-result/_id')
)

const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={PRIVATE_BASE} replace />,
  },
  {
    path: PRIVATE_BASE,
    element: <AdminLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: PRIVATE_USER_SETTINGS,
        element: <SuspenseHOC view={<UserSettings />} />,
      },
      {
        path: PRIVATE_PATIENTS,
        element: <SuspenseHOC view={<Patients />} />,
        children: [
          {
            path: PRIVATE_PATIENTS_DETAILS,
            element: <PatientDetailsAndEdit />,
          },
          { path: PRIVATE_PATIENT_EDIT, element: <PatientDetailsAndEdit /> },
        ],
      },
      {
        path: PRIVATE_COMPANIES,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <Companies />
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_COMPANY_DETAILS,
            element: <CompanyDetails />,
          },
        ],
      },
      {
        path: PRIVATE_EMPLOYEES,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <Employees />
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EMPLOYEE_DETAILS,
            element: <EmployeeDetails />,
          },
        ],
      },
      {
        path: PRIVATE_EXAMS,
        element: (
          <AdminProtectedRoute>
            {' '}
            <Navigate to={PRIVATE_EXAMS_SETTINGS} replace />
          </AdminProtectedRoute>
        ),
      },
      {
        path: PRIVATE_EXAMS_SETTINGS,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <ConfigurationLayoutProvider>
                  <ExamsSettings />
                </ConfigurationLayoutProvider>
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EXAMS_SETTINGS_BY_ID,
            element: <ExamsSettingsById />,
          },
          {
            path: PRIVATE_EXAMS_SETTINGS_CREATION,
            element: <ExamCreation />,
          },
        ],
      },
      {
        path: PRIVATE_EXAMS_FIELDS,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <ConfigurationLayoutProvider>
                  <ExamsFieldsConf />
                </ConfigurationLayoutProvider>
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EXAMS_FIELDS_CREATION,
            element: <ExamsFieldCreation />,
          },
          {
            path: PRIVATE_EXAMS_FIELDS_DETAILS,
            element: <ExamsFieldsConfDetails />,
          },
        ],
      },
      {
        path: PRIVATE_EXAM_COMPONENTS,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <ExamComponents />
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EXAM_COMPONENTS_DETAILS,
            element: <ExamComponentDetails />,
          },
        ],
      },
      {
        path: PRIVATE_EXAMS_PROFILES,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <ExamProfiles />
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EXAMS_PROFILES_DETAILS,
            element: <SuspenseHOC view={<ExamProfilesDetails />} />,
          },
        ],
      },
      {
        path: PRIVATE_EXAMS_PROFILES,
        element: (
          <SuspenseHOC
            view={
              <AdminProtectedRoute>
                <ExamProfiles />
              </AdminProtectedRoute>
            }
          />
        ),
        children: [
          {
            path: PRIVATE_EXAMS_PROFILES_DETAILS,
            element: <SuspenseHOC view={<ExamProfilesDetails />} />,
          },
        ],
      },
      {
        path: PRIVATE_RESULTS,
        element: <SuspenseHOC view={<Results />} />,
        children: [
          { path: PRIVATE_RESULTS_BY_EXAM, element: <ResultSecondStep /> },
        ],
      },
      {
        path: PRIVATE_ORDERS,
        element: <SuspenseHOC view={<Orders />} />,
        children: [
          {
            path: PRIVATE_ORDERS_COMPANY,
            element: <OrdersCompanies />,
            children: [
              {
                path: PRIVATE_ORDERS_COMPANY_DETAILS,
                element: <OrdersCompaniesDetails />,
              },
            ],
          },
          {
            path: PRIVATE_ORDERS_PATIENTS,
            element: <OrdersPatients />,
            children: [
              {
                path: PRIVATE_ORDERS_PATIENTS_DETAILS,
                element: <OrdersPatientsDetails />,
              },
            ],
          },
        ],
      },
      {
        path: PRIVATE_REVIEWS,
        element: <SuspenseHOC view={<Reviews />} />,
        children: [
          {
            path: PRIVATE_REVIEWS_ORDERS,
            element: <OrdersReviews />,
            children: [
              {
                path: PRIVATE_REVIEWS_ORDERS_DETAILS,
                element: <OrdersReviewsDetails />,
              },
            ],
          },
          {
            path: PRIVATE_REVIEWS_EXAMS_RESULTS,
            element: <ExamResultsReviews />,
            children: [
              {
                path: PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS,
                element: <ExamResultsReviewsDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: PUBLIC_LOGIN,
    element: (
      <SuspenseHOC
        fallback={
          <div className="flex justify-center items-center min-h-screen">
            <Spinner snake size="large" />
          </div>
        }
        view={<Login />}
      />
    ),
  },
  {
    path: PAGE_NOT_FOUND,
    element: <SuspenseHOC view={<Error404 />} />,
  },
  {
    path: SERVER_ERROR,
    element: <SuspenseHOC view={<Error500 />} />,
  },
]

export default appRoutes
