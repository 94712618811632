import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'

import type {
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
} from './types/common.types'
import type { Profile, ProfileQueryParams } from './types/profiles.types'

export const profilesApi = createApi({
  reducerPath: 'profilesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/catalogs/profiles`,
  }),
  tagTypes: ['Profiles'],
  endpoints: builder => ({
    profilesList: builder.query<
      CommonPaginationAPIResults<Profile>,
      CommonPaginationAPIRequest<ProfileQueryParams | void>
    >({
      query: params => {
        return {
          url: `/`,
          method: 'GET',
          params: params && {
            ...omit('pagination', params),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: [{ type: 'Profiles', id: 'PROFILES_LIST' }],
    }),
    profileDetails: builder.query<Profile, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Profiles', id: 'DETAILS' }],
    }),
  }),
})

export const { useLazyProfilesListQuery } = profilesApi
