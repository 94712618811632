import type { VFC } from 'react'
import { Button, Popover } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import { UserContent, UserTitle } from './UserDropdown'

const User: VFC = () => (
  <Popover
    placement="bottomRight"
    title={UserTitle}
    content={UserContent}
    trigger="click"
  >
    <Button type="text" className="topbar-option">
      <SettingOutlined />
    </Button>
  </Popover>
)

export default User
