export const BASE_URL_SERVER =
  (import.meta.env.VITE_BASE_URL as string) ??
  'https://nqz4gixr7c.execute-api.us-east-1.amazonaws.com/dev/api/v1'
export const SOCKET_ADDRESS = BASE_URL_SERVER
export const X_API_KEY =
  (import.meta.env.VITE_ENV as string) === 'development'
    ? null
    : (import.meta.env.VITE_X_API_KEY as string) ?? 'dummy_key'

export const SENTRY_DSN = (import.meta.env.VITE_SENTRY_DSN as string) ?? ''

export const DEFAULT_CURRENT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGINATION_SET = {
  currentPage: DEFAULT_CURRENT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
}
