import type { FC } from 'react'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

import {
  PRIVATE_BASE,
  PRIVATE_COMPANIES,
  PRIVATE_COMPANY_DETAILS_BASE,
  PRIVATE_EMPLOYEE_DETAILS_BASE,
  PRIVATE_EMPLOYEES,
  PRIVATE_EXAM_COMPONENTS,
  PRIVATE_EXAM_COMPONENTS_DETAILS_BASE,
  PRIVATE_EXAMS,
  PRIVATE_EXAMS_FIELDS,
  PRIVATE_EXAMS_FIELDS_CREATION,
  PRIVATE_EXAMS_FIELDS_DETAILS_BASE,
  PRIVATE_EXAMS_PROFILES,
  PRIVATE_EXAMS_PROFILES_DETAILS_BASE,
  PRIVATE_EXAMS_SETTINGS,
  PRIVATE_EXAMS_SETTINGS_CREATION,
  PRIVATE_ORDERS,
  PRIVATE_ORDERS_COMPANY,
  PRIVATE_ORDERS_COMPANY_DETAILS_BASE,
  PRIVATE_ORDERS_PATIENTS,
  PRIVATE_ORDERS_PATIENTS_DETAILS_BASE,
  PRIVATE_PATIENT_EDIT_BASE,
  PRIVATE_PATIENTS,
  PRIVATE_PATIENTS_DETAILS_BASE,
  PRIVATE_RESULTS,
  PRIVATE_REVIEWS,
  PRIVATE_REVIEWS_EXAMS,
  PRIVATE_REVIEWS_EXAMS_RESULTS,
  PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS,
  PRIVATE_REVIEWS_ORDERS,
} from '@breve/routes/route-collection'

interface BreadcrumbProps {
  className?: string
}

const Breadcrumb: FC<BreadcrumbProps> = props => {
  const { className = '' } = props
  const location = useLocation()

  const pathSnippets = location.pathname
    .split('/')
    .filter(i => i !== 'app' && i)

  const isDynamicLastSnippet = pathSnippets.find(possibleDynamicRoute =>
    possibleDynamicRoute.match(/[0-9]+/)
  )

  if (isDynamicLastSnippet === pathSnippets.at(-1)) {
    pathSnippets.pop()
  }

  const breadcrumbNameMap = useMemo(
    (): { [key: string]: { title: string; clickable: boolean } } => ({
      [PRIVATE_COMPANIES]: { title: 'Compañías', clickable: true },
      [PRIVATE_COMPANY_DETAILS_BASE]: {
        title: 'Detalles de compañía',
        clickable: true,
      },
      [PRIVATE_PATIENTS]: { title: 'Pacientes', clickable: true },
      [PRIVATE_PATIENTS_DETAILS_BASE]: {
        title: 'Detalles de paciente',
        clickable: true,
      },
      [PRIVATE_PATIENT_EDIT_BASE]: {
        title: 'Editar paciente',
        clickable: true,
      },
      [PRIVATE_EMPLOYEES]: { title: 'Empleados', clickable: true },
      [PRIVATE_EMPLOYEE_DETAILS_BASE]: {
        title: 'Detalles de empleados',
        clickable: true,
      },
      [PRIVATE_EXAMS]: { title: 'Exámenes', clickable: true },
      [PRIVATE_EXAMS_FIELDS]: {
        title: 'Configuración de campos',
        clickable: true,
      },
      [PRIVATE_EXAMS_FIELDS_DETAILS_BASE]: {
        title: 'Especificaciones de campo',
        clickable: false,
      },
      [PRIVATE_EXAMS_SETTINGS]: {
        title: 'Configuración de exámenes',
        clickable: true,
      },
      [PRIVATE_EXAM_COMPONENTS]: {
        title: 'Configuración de componentes de exámen',
        clickable: true,
      },
      [PRIVATE_EXAM_COMPONENTS_DETAILS_BASE]: {
        title: 'Detalles de componente de examen',
        clickable: true,
      },
      [PRIVATE_EXAMS_PROFILES]: {
        title: 'Perfiles de exámenes',
        clickable: true,
      },
      [PRIVATE_EXAMS_PROFILES_DETAILS_BASE]: {
        title: 'Detalles de perfil de exámenes',
        clickable: true,
      },
      [PRIVATE_ORDERS]: { title: 'Órdenes', clickable: true },
      [PRIVATE_ORDERS_COMPANY]: {
        title: 'Listado de Compañías',
        clickable: true,
      },
      [PRIVATE_ORDERS_COMPANY_DETAILS_BASE]: {
        title: 'Detalles de la orden (Compañía)',
        clickable: true,
      },
      [PRIVATE_ORDERS_PATIENTS]: {
        title: 'Listado de ordenes',
        clickable: true,
      },
      [PRIVATE_ORDERS_PATIENTS_DETAILS_BASE]: {
        title: 'Detalles de la orden',
        clickable: true,
      },
      [PRIVATE_EXAMS_FIELDS_CREATION]: {
        title: 'Crear campo de examen',
        clickable: true,
      },
      [PRIVATE_EXAMS_SETTINGS_CREATION]: {
        title: 'Crear formulario de examen',
        clickable: true,
      },
      [PRIVATE_RESULTS]: { title: 'Crear resultado', clickable: false },
      [PRIVATE_REVIEWS]: {
        title: 'Revisiones',
        clickable: false,
      },
      [PRIVATE_REVIEWS_EXAMS]: {
        title: 'Exámenes',
        clickable: false,
      },
      [PRIVATE_REVIEWS_EXAMS_RESULTS]: {
        title: 'Listado de resultados a revisar',
        clickable: true,
      },
      [PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS]: {
        title: 'Detalles de resultado a revisar',
        clickable: false,
      },
      [PRIVATE_REVIEWS_ORDERS]: {
        title: 'Listado de órdenes a revisar',
        clickable: false,
      },
    }),
    []
  )

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `${PRIVATE_BASE}${pathSnippets.slice(0, index + 1).join('/')}/`

    return {
      key: url,
      title: breadcrumbNameMap[url]?.clickable ? (
        <Link to={url}>{breadcrumbNameMap[url].title}</Link>
      ) : (
        <div>{breadcrumbNameMap[url]?.title}</div>
      ),
    }
  })

  const breadcrumbItems = [
    {
      key: 'home',
      title: (
        <Link to={PRIVATE_BASE}>
          <HomeOutlined style={{ verticalAlign: '0.125rem' }} />
          <span className="ml-2">Inicio</span>
        </Link>
      ),
    },
  ].concat(extraBreadcrumbItems)

  return (
    <>
      <h1 className="mb-2 text-lg font-normal">
        {breadcrumbNameMap[location.pathname]?.title ||
          breadcrumbNameMap[`${PRIVATE_BASE}${pathSnippets.join('/')}/`]
            ?.title ||
          'Inicio'}
      </h1>
      <AntBreadcrumb
        separator=">"
        className={className}
        items={breadcrumbItems}
      />
    </>
  )
}

export default Breadcrumb
