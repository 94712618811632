import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { ExamFieldsValue } from '@breve/validations/FieldSchema'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type {
  ExamField,
  ExamFieldQueryParams,
  ExamFieldsAssignmentToExamRequest,
  FieldsAssignmentToExamResponse,
} from './types/fields.types'

export const fieldsApi = createApi({
  reducerPath: 'fieldsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/catalogs/fields`,
  }),
  tagTypes: ['ExamFields'],
  endpoints: builder => ({
    fieldsList: builder.query<
      CommonPaginationAPIResults<ExamField>,
      CommonPaginationAPIRequest<ExamFieldQueryParams> | void
    >({
      query: params => {
        return {
          url: `/`,
          method: 'GET',
          params: params && {
            ...omit('pagination', params),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: [{ type: 'ExamFields', id: 'EXAM_FIELD_LIST' }],
    }),
    fieldDetails: builder.query<ExamField, number>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ExamFields', id: 'DETAILS' }],
    }),
    createField: builder.mutation<ExamField, ExamFieldsValue>({
      query: newField => ({
        url: '/',
        method: 'POST',
        data: newField,
      }),
      invalidatesTags: [
        { type: 'ExamFields', id: 'EXAM_FIELD_LIST' },
        { type: 'ExamFields', id: 'DETAILS' },
      ],
    }),
    updateField: builder.mutation<
      ExamField,
      CommonUpdateRequest<ExamFieldsValue>
    >({
      query: ({
        EntityToUpdate: updatedField,
        EntityIdToUpdate: fieldToUpdateId,
      }) => ({
        url: `/${fieldToUpdateId}/`,
        method: 'PUT',
        data: updatedField,
      }),
      invalidatesTags: [{ type: 'ExamFields', id: 'DETAILS' }],
    }),
    changeFieldStatus: builder.mutation<CommonDeleteResponse, ExamField['id']>({
      query: id => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ExamFields', id: 'EXAM_FIELD_LIST' },
        { type: 'ExamFields', id: 'DETAILS' },
      ],
    }),
    disableFieldRelationToExam: builder.mutation<
      CommonDeleteResponse,
      ExamField['id']
    >({
      query: id => ({
        url: `/disable-exam-field/${id}/`,
        method: 'PUT',
      }),
      invalidatesTags: [
        { type: 'ExamFields', id: 'EXAM_FIELD_LIST' },
        { type: 'ExamFields', id: 'DETAILS' },
      ],
    }),
  }),
})

export const examsAssignToFieldsApi = createApi({
  reducerPath: 'examsAssignToFieldsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/catalogs`,
  }),
  tagTypes: ['ExamFields'],
  endpoints: builder => ({
    massAssignPartialUpdateToExams: builder.mutation<
      FieldsAssignmentToExamResponse,
      ExamFieldsAssignmentToExamRequest
    >({
      query: ({ referenceField, examsToAssign, isActive }) => ({
        url: '/mass-assign-field/',
        method: 'PATCH',
        data: {
          referenceField,
          examCatalog: examsToAssign,
          isActive,
        },
        invalidatesTags: [{ type: 'ExamFields', id: 'DETAILS' }],
      }),
    }),
  }),
})

export const {
  useFieldsListQuery,
  useLazyFieldsListQuery,
  useFieldDetailsQuery,
  useCreateFieldMutation,
  useUpdateFieldMutation,
  useChangeFieldStatusMutation,
} = fieldsApi

export const { useMassAssignPartialUpdateToExamsMutation } =
  examsAssignToFieldsApi
