import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import applyCaseMiddleware from 'axios-case-converter'

import type { AuthResponse } from '@breve/services/types/auth.types'

import { BASE_URL_SERVER, X_API_KEY } from './constants.util'

export const getAccessToken = (): string | null =>
  localStorage.getItem('accessToken')

// const getRefreshToken = (): string | null =>
//   localStorage.getItem('refreshToken')

export const setCredentialsToken = (
  accessToken: string,
  refreshToken: string
): void => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
}

interface CredentialTokens {
  accessToken: string | null
  refreshToken: string | null
}

export const getCredentialsToken = (): CredentialTokens => {
  return {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  }
}

export const removeCredentialsToken = (): void => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

const breveAxios = axios.create({
  baseURL: BASE_URL_SERVER,
  headers: {
    'Content-Type': 'application/json',
    ...(X_API_KEY && { 'x-api-key': X_API_KEY }),
  },
})

breveAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.url && config.url === '/auth/') {
    return config
  }

  const accessToken = getAccessToken()
  const newHeaders: AxiosRequestHeaders = {}
  if (accessToken) {
    newHeaders.Authorization = `Bearer ${accessToken}`
  }
  return {
    ...config,
    headers: { ...config.headers, ...newHeaders },
  } as AxiosRequestConfig
})

const refreshAuth = async (): Promise<void | undefined> => {
  const refresh = localStorage.getItem('refreshToken')

  if (refresh) {
    try {
      const refreshResponse = await axios.post<AuthResponse>(
        `${BASE_URL_SERVER}/auth/refresh/`,
        {
          refresh,
        }
      )
      setCredentialsToken(refreshResponse.data.access, refresh)
      return Promise.resolve()
    } catch (error) {
      removeCredentialsToken()
      // eslint-disable-next-line no-console
      console.log('Promise Rejection', error)
      return Promise.reject(error)
    }
  }
  removeCredentialsToken()
  return Promise.reject()
}

createAuthRefreshInterceptor(breveAxios, refreshAuth)

const caseOptions = {
  ignoreHeaders: true,
}

export default applyCaseMiddleware(breveAxios, caseOptions)
