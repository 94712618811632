import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { Patient } from '@breve/services/types/patient.types'

export type PatientsState = {
  patientsList: Patient[] | null
}

export const defaultPatientState: PatientsState = {
  patientsList: null,
}

const patientSlice = createSlice({
  name: 'patient',
  initialState: defaultPatientState,
  reducers: {
    setPatientsList: (
      state,
      { payload: { patientsList } }: PayloadAction<PatientsState>
    ) => {
      state.patientsList = patientsList
    },
  },
})

export const { setPatientsList } = patientSlice.actions

export default patientSlice.reducer
