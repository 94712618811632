import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { OrderCompanyValues } from '@breve/validations/orders/OrderCompanySchema'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from '../types/common.types'
import type {
  OrderCompany,
  OrderCompanyQueryParams,
} from '../types/orders.types'

type APIResponseOrderCompany = Omit<OrderCompany, 'companyId'> & {
  company: number
}

export const companyOrdersApi = createApi({
  reducerPath: 'companyOrdersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/company-orders`,
  }),
  tagTypes: ['CompanyOrder'],
  endpoints: builder => ({
    companyOrderList: builder.query<
      CommonPaginationAPIResults<OrderCompany>,
      CommonPaginationAPIRequest<OrderCompanyQueryParams> | undefined
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'CompanyOrder', id: 'COMPANY_ORDER_LIST' }],
    }),
    orderCompanyDetail: builder.query<OrderCompany, string>({
      query: (orderCompanyId: string) => ({
        url: `/${orderCompanyId}/`,
        method: 'GET',
      }),
      transformResponse: (
        response: APIResponseOrderCompany
      ): OrderCompany | Promise<OrderCompany> => ({
        ...(omit<APIResponseOrderCompany>(
          'company',
          response
        ) as unknown as OrderCompany),
        companyId: response.company,
      }),
      providesTags: [{ type: 'CompanyOrder', id: 'DETAILS' }],
    }),
    createOrderCompany: builder.mutation<OrderCompany, OrderCompanyValues>({
      query: newOrderCompany => ({
        url: '/',
        method: 'POST',
        data: newOrderCompany,
      }),
      invalidatesTags: [
        { type: 'CompanyOrder', id: 'COMPANY_ORDER_LIST' },
        { type: 'CompanyOrder', id: 'DETAILS' },
      ],
    }),
    updateOrderCompany: builder.mutation<
      OrderCompany,
      CommonUpdateRequest<OrderCompanyValues>
    >({
      query: ({
        EntityToUpdate: updatedOrderCompany,
        EntityIdToUpdate: orderCompanyToUpdateId,
      }) => ({
        url: `/${orderCompanyToUpdateId}/`,
        method: 'PUT',
        data: updatedOrderCompany,
      }),
      invalidatesTags: [
        { type: 'CompanyOrder', id: 'COMPANY_ORDER_LIST' },
        { type: 'CompanyOrder', id: 'DETAILS' },
      ],
    }),
    deleteOrderCompany: builder.mutation<CommonDeleteResponse, string>({
      query: orderCompanyIdToDelete => ({
        url: `/${orderCompanyIdToDelete}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'CompanyOrder', id: 'COMPANY_ORDER_LIST' },
        { type: 'CompanyOrder', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useCompanyOrderListQuery,
  useLazyCompanyOrderListQuery,
  useOrderCompanyDetailQuery,
  useCreateOrderCompanyMutation,
  useUpdateOrderCompanyMutation,
  useDeleteOrderCompanyMutation,
} = companyOrdersApi
