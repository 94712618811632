import type { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'

import breveAxios from '@breve/utils/http.util'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      headers?: AxiosRequestHeaders
      data?: unknown
      params?: unknown
    },
    unknown,
    unknown
  > =>
  async ({ url, method, headers, data, params }) => {
    try {
      const result = await breveAxios({
        url: baseUrl + url,
        method,
        headers,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError<unknown, unknown>
      // eslint-disable-next-line no-console
      console.log(err)
      return {
        error: {
          status: err?.response?.status || 500,
          data: err?.response?.data || err.message,
        },
      }
    }
  }

export default axiosBaseQuery
