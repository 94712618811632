import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
} from '../types/common.types'
import type {
  OrderReviews,
  OrderReviewsCreate,
} from '../types/reviews/order-reviews.types'

export const companyOrdersReviewsApi = createApi({
  reducerPath: 'companyOrdersReviewsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/company-orders-reviews`,
  }),
  tagTypes: ['OrdersReviews'],
  endpoints: builder => ({
    CompanyOrdersReviewList: builder.query<
      CommonPaginationAPIResults<OrderReviews>,
      CommonPaginationAPIRequest | undefined
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'OrdersReviews', id: 'ORDER_REVIEWS_LIST' }],
    }),
    companyOrderReviewDetails: builder.query<OrderReviews, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'OrdersReviews', id: 'DETAILS' }],
    }),
    createCompanyOrderReview: builder.mutation<
      OrderReviews,
      OrderReviewsCreate
    >({
      query: newCompanyOrderReview => ({
        url: '/',
        method: 'POST',
        data: newCompanyOrderReview,
      }),
      invalidatesTags: [
        { type: 'OrdersReviews', id: 'COMPANY_ORDER_REVIEWS_LIST' },
        { type: 'OrdersReviews', id: 'DETAILS' },
      ],
    }),
    // updateCompanyOrdersReviewStatus: builder.mutation<
    //   OrderReviews,
    //   CommonUpdateRequest<{
    //     status: CompanyOrderReviews['status']
    //   }>
    // >({
    //   query: ({
    //     EntityToUpdate: updatedExamResultReview,
    //     EntityIdToUpdate: examResultReviewToUpdateId,
    //   }) => ({
    //     url: `/${examResultReviewToUpdateId}/`,
    //     method: 'PATCH',
    //     data: updatedExamResultReview,
    //   }),
    //   invalidatesTags: [
    //     { type: 'CompanyOrdersReview', id: 'COMPANY_ORDER_REVIEWS_LIST' },
    //     { type: 'CompanyOrdersReview', id: 'DETAILS' },
    //   ],
    // }),
    rejectCompanyOrderReview: builder.mutation<CommonDeleteResponse, string>({
      query: examResultReviewId => ({
        url: `/${examResultReviewId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'OrdersReviews', id: 'COMPANY_ORDER_REVIEWS_LIST' },
        { type: 'OrdersReviews', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useCompanyOrdersReviewListQuery,
  useLazyCompanyOrdersReviewListQuery,
  useCompanyOrderReviewDetailsQuery,
  useCreateCompanyOrderReviewMutation,
  useRejectCompanyOrderReviewMutation,
} = companyOrdersReviewsApi
