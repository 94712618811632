import { concatSymbols } from '@breve/utils'

import PATHS_COLLECTIONS from './paths.routes'

const {
  PRIVATE_PATHS: {
    PRIVATE_BASE_PATH,
    USER_PATHS: { BASE_PATH },
    EXAMPLE_PATHS: { CHARTJS, TABLES },
    PATIENTS_PATHS: {
      BASE_PATH: PATIENT_PATH,
      DETAILS_PATH: PATIENT_DETAILS_PATH,
      EDIT: PATIENT_EDIT,
    },
    COMPANIES_PATHS: {
      BASE_PATH: COMPANY_PATH,
      DETAILS_PATH: COMPANY_DETAILS_PATH,
    },
    EMPLOYEES_PATHS: {
      BASE_PATH: EMPLOYEES_PATH,
      DETAILS_PATH: EMPLOYEE_DETAILS_PATH,
    },
    EXAMS_PATHS: {
      BASE_PATH: EXAMS_PATH,
      CREATE_PATH: EXAMS_CREATE_PATH,
      FIELDS_PATH: {
        BASE_PATH: EXAMS_FIELDS_PATH,
        DETAILS_PATH: EXAMS_FIELDS_DETAILS_PATH,
      },
      SETTINGS_PATH: { BASE_PATH: EXAMS_SETTINGS_PATH },
      COMPONENTS_PATH: {
        BASE_PATH: EXAMS_COMPONENTS_PATH,
        DETAILS_PATH: EXAMS_COMPONENTS_DETAILS_PATH,
      },
      PROFILES_PATH: {
        BASE_PATH: EXAMS_PROFILES_PATH,
        DETAILS_PATH: EXAMS_PROFILES_DETAILS_PATH,
      },
      RESULTS_PATH: {
        BASE_BATH: RESULTS_PATH,
        REVIEW_BASE_PATH: RESULT_REVIEWS_PATH,
      },
    },
    ORDERS_PATH: { BASE_PATH: ORDERS_PATH, DETAILS_PATH: ORDERS_DETAILS_PATH },
    REVIEWS_PATH: {
      BASE_PATH: REVIEWS_PATH,
      DETAILS_PATH: REVIEWS_DETAILS_PATH,
    },
  },
  PUBLIC_PATHS: {
    BASE,
    DYNAMIC_IDS,
    USER_PATHS: { LOGIN, FORGOT_PASSWORD, REGISTER },
    CLIENT_ERRORS_PATHS: { NOT_FOUND, INTERNAL_SERVER_ERROR },
  },
} = PATHS_COLLECTIONS

export const PRIVATE_BASE = concatSymbols([BASE, PRIVATE_BASE_PATH])
export const PRIVATE_USER_SETTINGS = concatSymbols([PRIVATE_BASE, BASE_PATH])
export const PRIVATE_TABLES = concatSymbols([PRIVATE_BASE, TABLES])
export const PRIVATE_CHARTJS = concatSymbols([PRIVATE_BASE, CHARTJS])

/**
 * @summary Patients Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_PATIENTS = concatSymbols([PRIVATE_BASE, PATIENT_PATH])
export const PRIVATE_PATIENTS_DETAILS_BASE = concatSymbols([
  PRIVATE_PATIENTS,
  PATIENT_DETAILS_PATH,
])
export const PRIVATE_PATIENTS_DETAILS = concatSymbols([
  PRIVATE_PATIENTS_DETAILS_BASE,
  DYNAMIC_IDS,
])
export const PRIVATE_PATIENT_EDIT_BASE = concatSymbols([
  PRIVATE_PATIENTS,
  PATIENT_EDIT,
])
export const PRIVATE_PATIENT_EDIT = concatSymbols([
  PRIVATE_PATIENT_EDIT_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Companies Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_COMPANIES = concatSymbols([PRIVATE_BASE, COMPANY_PATH])

export const PRIVATE_COMPANY_DETAILS_BASE = concatSymbols([
  PRIVATE_COMPANIES,
  COMPANY_DETAILS_PATH,
])

export const PRIVATE_COMPANY_DETAILS = concatSymbols([
  PRIVATE_COMPANY_DETAILS_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Employee Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_EMPLOYEES = concatSymbols([PRIVATE_BASE, EMPLOYEES_PATH])

export const PRIVATE_EMPLOYEE_DETAILS_BASE = concatSymbols([
  PRIVATE_EMPLOYEES,
  EMPLOYEE_DETAILS_PATH,
])

export const PRIVATE_EMPLOYEE_DETAILS = concatSymbols([
  PRIVATE_EMPLOYEE_DETAILS_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Exams Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_EXAMS = concatSymbols([PRIVATE_BASE, EXAMS_PATH])
export const PRIVATE_EXAMS_FIELDS = concatSymbols([
  PRIVATE_EXAMS,
  EXAMS_FIELDS_PATH,
])
export const PRIVATE_EXAMS_SETTINGS = concatSymbols([
  PRIVATE_EXAMS,
  EXAMS_SETTINGS_PATH,
])
export const PRIVATE_EXAMS_SETTINGS_BY_ID = concatSymbols([
  PRIVATE_EXAMS_SETTINGS,
  DYNAMIC_IDS,
])
export const PRIVATE_EXAMS_SETTINGS_CREATION = concatSymbols([
  PRIVATE_EXAMS_SETTINGS,
  EXAMS_CREATE_PATH,
])
export const PRIVATE_EXAMS_FIELDS_DETAILS_BASE = concatSymbols([
  PRIVATE_EXAMS_FIELDS,
  EXAMS_FIELDS_DETAILS_PATH,
])
export const PRIVATE_EXAMS_FIELDS_CREATION = concatSymbols([
  PRIVATE_EXAMS_FIELDS_DETAILS_BASE,
  EXAMS_CREATE_PATH,
])

export const PRIVATE_EXAMS_FIELDS_DETAILS = concatSymbols([
  PRIVATE_EXAMS_FIELDS_DETAILS_BASE,
  DYNAMIC_IDS,
])

export const PRIVATE_EXAM_COMPONENTS = concatSymbols([
  PRIVATE_EXAMS,
  EXAMS_COMPONENTS_PATH,
])

export const PRIVATE_EXAM_COMPONENTS_DETAILS_BASE = concatSymbols([
  PRIVATE_EXAM_COMPONENTS,
  EXAMS_COMPONENTS_DETAILS_PATH,
])

export const PRIVATE_EXAM_COMPONENTS_DETAILS = concatSymbols([
  PRIVATE_EXAM_COMPONENTS_DETAILS_BASE,
  DYNAMIC_IDS,
])

export const PRIVATE_RESULTS = concatSymbols([PRIVATE_EXAMS, RESULTS_PATH])

export const PRIVATE_RESULTS_BY_EXAM = concatSymbols([
  PRIVATE_RESULTS,
  DYNAMIC_IDS,
])

export const PRIVATE_EXAMS_PROFILES = concatSymbols([
  PRIVATE_EXAMS,
  EXAMS_PROFILES_PATH,
])

export const PRIVATE_EXAMS_PROFILES_DETAILS_BASE = concatSymbols([
  PRIVATE_EXAMS_PROFILES,
  EXAMS_PROFILES_DETAILS_PATH,
])

export const PRIVATE_EXAMS_PROFILES_DETAILS = concatSymbols([
  PRIVATE_EXAMS_PROFILES_DETAILS_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Orders Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_ORDERS = concatSymbols([PRIVATE_BASE, ORDERS_PATH])
export const PRIVATE_ORDERS_COMPANY = concatSymbols([
  PRIVATE_ORDERS,
  COMPANY_PATH,
])
export const PRIVATE_ORDERS_COMPANY_DETAILS_BASE = concatSymbols([
  PRIVATE_ORDERS_COMPANY,
  ORDERS_DETAILS_PATH,
])
export const PRIVATE_ORDERS_COMPANY_DETAILS = concatSymbols([
  PRIVATE_ORDERS_COMPANY_DETAILS_BASE,
  DYNAMIC_IDS,
])

export const PRIVATE_ORDERS_PATIENTS = concatSymbols([
  PRIVATE_ORDERS,
  PATIENT_PATH,
])
export const PRIVATE_ORDERS_PATIENTS_DETAILS_BASE = concatSymbols([
  PRIVATE_ORDERS_PATIENTS,
  ORDERS_DETAILS_PATH,
])
export const PRIVATE_ORDERS_PATIENTS_DETAILS = concatSymbols([
  PRIVATE_ORDERS_PATIENTS_DETAILS_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Reviews Module related Routes and Paths
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PRIVATE_REVIEWS = concatSymbols([PRIVATE_BASE, REVIEWS_PATH])
export const PRIVATE_REVIEWS_ORDERS = concatSymbols([
  PRIVATE_REVIEWS,
  ORDERS_PATH,
])
export const PRIVATE_REVIEWS_ORDERS_DETAILS_BASE = concatSymbols([
  PRIVATE_REVIEWS_ORDERS,
  REVIEWS_DETAILS_PATH,
])
export const PRIVATE_REVIEWS_ORDERS_DETAILS = concatSymbols([
  PRIVATE_REVIEWS_ORDERS_DETAILS_BASE,
  DYNAMIC_IDS,
])

export const PRIVATE_REVIEWS_EXAMS = concatSymbols([
  PRIVATE_REVIEWS,
  EXAMS_PATH,
])
export const PRIVATE_REVIEWS_EXAMS_RESULTS = concatSymbols([
  PRIVATE_REVIEWS_EXAMS,
  RESULT_REVIEWS_PATH,
])
export const PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS_BASE = concatSymbols([
  PRIVATE_REVIEWS_EXAMS_RESULTS,
  REVIEWS_DETAILS_PATH,
])
export const PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS = concatSymbols([
  PRIVATE_REVIEWS_EXAMS_RESULTS_DETAILS_BASE,
  DYNAMIC_IDS,
])

/**
 * @summary Public Routes and Paths that don't need authentication
 * @author Luisangel Marcia | @CosmicTiger
 */
export const PUBLIC_LOGIN = concatSymbols([BASE, LOGIN])
export const PUBLIC_REGISTER = concatSymbols([BASE, REGISTER])
export const PUBLIC_FORGOT_PASSWORD = concatSymbols([BASE, FORGOT_PASSWORD])

export const PAGE_NOT_FOUND = concatSymbols([BASE, NOT_FOUND])
export const SERVER_ERROR = concatSymbols([BASE, INTERNAL_SERVER_ERROR])
