import type { Timestamped } from './common.types'
import type { Company } from './company.types'
import type { Patient } from './patient.types'

// region OrderTimeline
export interface OrderTimeline extends Timestamped {
  id: string
  companyOrder: string
  message: string
  type: 'NOTIFICATION' | 'MESSAGE' | 'ALERT'
}
// endregion

// region OrderCompany related types
export interface OrderCompany extends Timestamped {
  id: string
  companyName: Company['name']
  companyId: Company['id']
  description: string
  status: number
  deliveryDate: string
  createdBy: string
  lastUpdatedBy: string
  refNumber: number
  completionPercentage: number
  timeline?: OrderTimeline[]
  pendingOrders?: {
    id: string
    patientName: string
    patientSex: string
    deliveryDate: string
    refNumber: number
  }[]
}

export interface OrderCompanyQueryParams {
  description?: string
  status?: string
  refNumber?: string
}
// endregion

// region OrderDetail related types

export interface OrderDetail {
  id: string
  examCatalog: ExamCatalog
  hasResults: boolean
  results?: {
    id: string
    isValid: boolean
  }[]
}

export interface ExamCatalog {
  id: number
  codeName: string
  name: string
}
// endregion

// region Order related types
export enum OrderStatusEnum {
  PENDING = 1,
  IN_PROGRESS = 2,
  CANCELLED = 3,
  FINISHED = 4,
}

export interface Order extends Timestamped {
  id: string
  patient: number
  patientName: string
  patientIdentification?: string
  deliveryDate: string
  createdBy: string
  lastUpdatedBy: string
  companyName?: string
  details?: OrderDetail[]
  completionPercentage: number
  refNumber: number
  status: number
  urlResults: string
  timeline?: OrderTimeline[]
}

export interface OrderQueryParams {
  created_by?: string
  lastUpdatedBy?: string
  withExam?: number
  withPendingExam?: number
  page?: number
  pageSize?: number
  patientIdentification?: string
  patientName?: string
  refNumber?: string
  status?: string
  type?: OrderType
  search?: string
}

export interface OrderUpdateValue {
  deliveryDate: string
  exams: number[]
}

export interface OrderCreateValue extends OrderUpdateValue {
  companyOrderId?: string
  patientId: number
  typeOrder: OrderType
}

export interface CreateOtpRequest {
  sendTo: string
  sendVia: 'MAIL'
  expiresIn: number
  meta: {
    [x: string]: string
  }
}

export interface CreateOtpValue {
  sendTo: string
  idValue: string
  idType: 'companyOrderId' | 'orderId'
}

export interface CreateOtpResponse {
  challengeId: string
  expires: string
  status: number
}

// endregion

// region OrderToCheck related types
export enum OrderType {
  'company' = 1,
  'private' = 2,
}

export interface OrderToCheck extends Timestamped {
  id: string
  patient: number
  patientName?: string
  patientSex?: string
  patientInfo?: Patient
  companyName?: Company['name']
  companyId?: Company['id']
  description?: string
  deliveryDate: string
  createdBy: string
  lastUpdatedBy: string
  details?: OrderDetail[]
  type?: OrderType
  exams?: number[]
  profiles?: number[]
  completionPercentage?: number
  refNumber: number
  status: number
  urlResults: string
  timeline?: OrderTimeline[]
  pendingOrders?: {
    id: string
    patientName: string
    patientSex: string
    deliveryDate: string
    refNumber: number
  }[]
}
// endregion
