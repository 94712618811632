import { createApi } from '@reduxjs/toolkit/query/react'

import Alerts from '@breve/components/Alerts'
import type {
  Employee,
  EmployeeRequest,
} from '@breve/services/types/employee.types'
import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { omit } from '@breve/utils/omit.util'

import { formatRTKPaginationAPI } from '../utils/mappings.util'
import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type { RTKQueryRawErrorResponse } from './types/errors.types'

export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/employees`,
  }),
  tagTypes: ['Employee'],
  endpoints: builder => ({
    employeeList: builder.query<
      CommonPaginationAPIResults<Employee>,
      CommonPaginationAPIRequest | undefined
    >({
      query: params => {
        return {
          url: '/',
          method: 'GET',
          params: params && {
            ...omit('pagination', params),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: response =>
        response
          ? [
              ...response.results.map(({ id }) => ({
                type: 'Employee' as const,
                id,
              })),
              'Employee',
            ]
          : ['Employee'],
    }),
    employeeDetail: builder.query<Employee, number>({
      query: (employeeId: number) => ({
        url: `/${employeeId}/`,
        method: 'GET',
      }),
    }),
    createEmployee: builder.mutation<Employee, EmployeeRequest>({
      query: newEmployee => ({
        url: '/',
        method: 'POST',
        data: newEmployee,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await Alerts.loading(
          'Enviando información a servidor para registrar la compañía que está creando'
        )
        try {
          const { data: newEmployee } = await queryFulfilled
          // `onSuccess` side-effect
          if (newEmployee) {
            await Alerts.success(
              `El empleado ${newEmployee.user.username} ha sido registrada exitosamente`
            )
          }
        } catch ({ error }) {
          // `onError` side-effect
          const { data } = error as RTKQueryRawErrorResponse
          await Alerts.apiError(data)
        }
      },
      invalidatesTags: ['Employee'],
    }),
    updateEmployee: builder.mutation<
      Employee,
      CommonUpdateRequest<EmployeeRequest>
    >({
      query: ({
        EntityToUpdate: updatedCompany,
        EntityIdToUpdate: companyToUpdateId,
      }) => ({
        url: `/${companyToUpdateId}/`,
        method: 'PUT',
        data: updatedCompany,
      }),
      /**
       * @see https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates
       */
      onQueryStarted: async (_, { queryFulfilled }) => {
        await Alerts.loading(
          'Enviando información a servidor para registrar los cambios la compañía que está actualizando'
        )
        try {
          const { data: updatedEmployee } = await queryFulfilled
          if (updatedEmployee) {
            await Alerts.success(
              `La compañía ${updatedEmployee.user.username} ha sido actualizada exitosamente`
            )
          }
        } catch ({ error }) {
          // `onError` side-effect
          const { data } = error as RTKQueryRawErrorResponse
          await Alerts.apiError(data)
        }
      },
      /**
       * @see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
       * @param result
       * @returns
       */
      invalidatesTags: result => [{ type: 'Employee', id: result?.id }],
    }),
    deleteEmployee: builder.mutation<CommonDeleteResponse, number | string>({
      query: companyIdToDelete => ({
        url: `/${companyIdToDelete}/`,
        method: 'DELETE',
      }),
      /**
       * @see https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates
       */
      onQueryStarted: async (_, { queryFulfilled }) => {
        await Alerts.loading('Desactivando empleado')
        try {
          const {
            data: { message },
          } = (await queryFulfilled) as unknown as CommonDeleteResponse
          // eslint-disable-next-line no-console
          await Alerts.success(message)
        } catch (error) {
          // `onError` side-effect
          // console.log(error)
        }
      },
      /**
       * @see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
       * @param result
       * @returns
       */
      invalidatesTags: ['Employee'],
    }),
  }),
})

export const {
  useEmployeeListQuery,
  useEmployeeDetailQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeesApi
