import type { VFC } from 'react'
import { useMemo, useState } from 'react'
import { Layout, Typography } from 'antd'
import type { CollapseType } from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import { useWindowSize } from 'utils'
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'

import logo from '@breve/assets/images/labsys/whiteLogo.ico'

import Menu from './Menu'
import ResponsiveHeader from './ResponsiveHeader'

const { Title } = Typography

const SideNav: VFC = () => {
  const [width, height] = useWindowSize()
  const [isCollapsed, setCollapsed] = useState(false)
  const [isPinned, setIsPinned] = useState(true)
  const [isHovered, setHovered] = useState(false)

  const isDesktop = useMemo(() => width >= 992, [width])

  const onCollapse = (_collapsed = false, type: CollapseType): void => {
    if (type === 'clickTrigger') {
      if (!isPinned) {
        setIsPinned(_collapsed)
      } else {
        setIsPinned(!_collapsed)
      }
    }
  }

  const onBreakpoint = (broken: boolean): void => {
    if (!broken && !isPinned) {
      setCollapsed(true)
    } else {
      setCollapsed(broken)
    }
  }

  const onHover = {
    onMouseEnter: (): void => {
      if (!isPinned && isDesktop) {
        setCollapsed(false)
        setHovered(true)
      }
    },
    onMouseLeave: (): void => {
      if (!isPinned && isDesktop) {
        setCollapsed(true)
        setHovered(false)
      }
    },
  }

  const handleResponsiveOpen = (): void => {
    setCollapsed(!isCollapsed)
  }

  const pinTrigger = !isPinned ? (
    <PushpinOutlined className="text-xl" />
  ) : (
    <PushpinFilled className="text-xl" />
  )

  const menuWrapperStyle = {
    maxHeight: isDesktop ? `${height - 70 - 48}px` : '100vh',
    overflow: 'overlay',
  }

  // NOTE: Breakpoint "lg" is equal to 992px
  return (
    <>
      <div
        className={classNames(
          'sidenav__backshadow',
          !isPinned && isDesktop ? 'block' : 'hidden'
        )}
      />
      <div
        className={classNames('sidenav__bg', {
          'sidenav__bg-visible':
            (!isPinned && isHovered && !isCollapsed && isDesktop) ||
            (!isCollapsed && !isDesktop),
        })}
        onClick={!isDesktop ? handleResponsiveOpen : undefined}
        aria-hidden="true"
      />
      <ResponsiveHeader handleOpen={handleResponsiveOpen} />
      <Layout.Sider
        breakpoint="lg"
        className={classNames('sidenav', {
          sidenav_responsive: !isDesktop,
          'absolute h-full': !isPinned && isDesktop,
        })}
        collapsedWidth={isDesktop ? '80' : '0'}
        zeroWidthTriggerStyle={{ display: 'none' }}
        collapsed={isCollapsed}
        collapsible={isDesktop}
        onBreakpoint={onBreakpoint}
        onCollapse={onCollapse}
        onMouseEnter={onHover.onMouseEnter}
        onMouseLeave={onHover.onMouseLeave}
        trigger={pinTrigger}
        width={298}
      >
        <div className={classNames('sidenav__logo', { hidden: !isDesktop })}>
          <div className="bg-white p-2 rounded-full">
            <img
              src={logo}
              alt="Labsys logo"
              className="w-5 min-w-[20px] h-5 rounded-md object-contain"
            />
          </div>
          <Title
            level={1}
            className={classNames('sidenav__title', {
              'sidenav__title-collapsed': !isPinned,
            })}
          >
            {/* Labsys 2 */}
            Labsys
          </Title>
        </div>
        {/* minus 70px for the header height and minu 48px for the bottom pin button height */}
        <div style={menuWrapperStyle}>
          <Menu />
        </div>
      </Layout.Sider>
    </>
  )
}

export default SideNav
