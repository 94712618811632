import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { ExamProfileValues } from '@breve/validations/ExamProfileSchema'

import type {
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type { ExamProfile } from './types/exams-profiles.types'

export const examsProfilesApi = createApi({
  reducerPath: 'examsProfilesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `catalogs/profiles`,
  }),
  tagTypes: ['ExamProfile'],
  endpoints: builder => ({
    examsProfilesList: builder.query<
      CommonPaginationAPIResults<ExamProfile>,
      CommonPaginationAPIRequest | void
    >({
      query: params => ({
        url: `/`,
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'ExamProfile', id: 'EXAM_PROFILE_LIST' }],
    }),
    examProfileDetails: builder.query<ExamProfile, string>({
      query: id => ({
        url: `/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ExamProfile', id: 'DETAILS' }],
    }),
    createExamProfile: builder.mutation<ExamProfile, ExamProfileValues>({
      query: newExamProfile => ({
        url: `/`,
        method: 'POST',
        data: newExamProfile,
      }),
      invalidatesTags: [
        { type: 'ExamProfile', id: 'EXAM_PROFILE_LIST' },
        { type: 'ExamProfile', id: 'DETAILS' },
      ],
    }),
    updateExamProfile: builder.mutation<
      ExamProfile,
      CommonUpdateRequest<ExamProfileValues>
    >({
      query: ({
        EntityToUpdate: updatedExamProfile,
        EntityIdToUpdate: examProfileToUpdateId,
      }) => ({
        url: `/${examProfileToUpdateId}/`,
        method: 'PUT',
        data: updatedExamProfile,
      }),
      invalidatesTags: [
        { type: 'ExamProfile', id: 'EXAM_PROFILE_LIST' },
        { type: 'ExamProfile', id: 'DETAILS' },
      ],
    }),
    deleteExamProfile: builder.mutation<ExamProfile, string | number>({
      query: id => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ExamProfile', id: 'EXAM_PROFILE_LIST' },
        { type: 'ExamProfile', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useExamsProfilesListQuery,
  useLazyExamsProfilesListQuery,
  useExamProfileDetailsQuery,
  useCreateExamProfileMutation,
  useUpdateExamProfileMutation,
  useDeleteExamProfileMutation,
} = examsProfilesApi
