import type { FC } from 'react'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/es_ES'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'

import AppRouter from './App.routes'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
)

const App: FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Montserrat',
          colorPrimary: '#023D51',
          colorPrimaryBg: '#6FBFC3',
          colorBgBase: '#FFFFFF',
        },
      }}
      locale={locale}
    >
      <div className="min-h-screen" data-testid="appFrame" id="div">
        <AppRouter />
      </div>
    </ConfigProvider>
  )
}

export default App
