import type { FC } from 'react'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import classNames from 'classnames'

import { Breadcrumb } from '@breve/components/Breadcrumb'
import { Header as HeaderContent } from '@breve/components/Header'
import SideNav from '@breve/components/SideNav'
import Spinner from '@breve/components/Spinner'
import { useAuth } from '@breve/hooks/auth/useAuth'
import { useWindowSize } from '@breve/utils'

const { Header, Content } = Layout

const AdminLayout: FC = () => {
  const location = useLocation()
  const [width] = useWindowSize()
  const [loading, setLoading] = useState<boolean>(true)
  const { user, hasLoggedOut } = useAuth()

  const isDesktop = useMemo<boolean>(() => width >= 992, [width])

  useEffect((): (() => void) => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2500)

    if (!user) {
      return () => {
        clearTimeout(timer)
        return null
      }
    }

    return () => clearTimeout(timer)
  }, [user, loading])

  if (hasLoggedOut || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner snake size="large" />
      </div>
    )
  }

  // eslint-disable-next-line no-console
  // console.log('user', user)

  return user ? (
    <Layout className={classNames('admin-layout', { 'flex-col': !isDesktop })}>
      <SideNav />
      <Layout
        className={classNames('admin-layout__main', { 'w-full': !isDesktop })}
      >
        <Header className="admin-layout__header">
          <HeaderContent />
        </Header>
        <Content style={{ overflow: 'auto' }} className="p-4 md:p-8">
          <Breadcrumb className="pb-4" />
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-full">
                <Spinner snake size="large" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  )
}

export default AdminLayout
