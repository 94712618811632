import type { FC } from 'react'
import { useRoutes } from 'react-router-dom'

import routesCollections from './routes'

const AppRouter: FC = () => {
  const element = useRoutes(routesCollections.appRoutes)

  return element
}

export default AppRouter
