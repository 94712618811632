import dayjs from 'dayjs'

import type {
  CommonPaginationAPIRequestRTK,
  CommonPaginationTables,
} from '@breve/services/types/common.types'
import type {
  Employee,
  EmployeeRequest,
} from '@breve/services/types/employee.types'
import type {
  Order,
  OrderCreateValue,
  OrderUpdateValue,
} from '@breve/services/types/orders.types'
import { OrderType } from '@breve/services/types/orders.types'
import type { EmployeeValues } from '@breve/validations/EmployeeSchema'
import type { OrderValues } from '@breve/validations/OrderSchema'

export const mapEmployeeToFormValues = (
  employee?: Employee
): EmployeeValues | undefined => {
  if (!employee) return undefined
  return {
    firstName: employee.firstName,
    secondName: employee.secondName || '',
    firstSurname: employee.firstSurname,
    secondSurname: employee.secondSurname || '',
    birthdate: employee.birthdate,
    isYoung: false,
    idNumber: employee.idNumber,
    sex: employee.sex,
    phone: employee.phone || '',
    email: employee.email || '',
    address: employee.address,
    workArea: employee.workArea,
    username: employee.user.username,
    roles: employee.user.groups.map(group => group.id),
    password: '',
    passwordConfirm: '',
    profilePhoto: employee.profilePhoto || '',
  }
}

export const mapEmployeeFormValuesToRequest = (
  employeeFormValues: EmployeeValues
): EmployeeRequest | undefined => {
  if (!employeeFormValues) return undefined
  const { username, password, passwordConfirm, roles, ...employeePerson } =
    employeeFormValues
  return {
    ...employeePerson,
    birthdate: dayjs(employeePerson.birthdate).format('YYYY-MM-DD'),
    userData: {
      username,
      password,
      passwordConfirm,
      roles,
    },
  }
}

export const mapOrderToFormValues = (
  order?: Order
): OrderValues | undefined => {
  if (!order) return undefined
  return {
    patientId: order.patient,
    deliveryDate: order.deliveryDate,
    isCompany: order.companyName !== null,
    companyOrderId: '',
    exams:
      order.details?.map(detail => ({
        label: detail.examCatalog.name,
        key: detail.examCatalog.id,
      })) || [],
  }
}

export const mapOrderFormValuesToRequest = (
  orderFormValues: OrderValues,
  isEdit = false
): OrderCreateValue | OrderUpdateValue | undefined => {
  if (!orderFormValues) return undefined
  const { patientId, deliveryDate, isCompany, companyOrderId, exams } =
    orderFormValues
  return {
    ...(!isEdit && {
      patientId,
      typeOrder: isCompany
        ? OrderType[OrderType.company]
        : OrderType[OrderType.private],
      ...(isCompany && { companyOrderId }),
    }),
    deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD HH:mm:ss'),
    exams: exams.map(exam => exam.key as number),
  }
}

export const formatRTKPaginationAPI = (
  pagination: CommonPaginationTables
): CommonPaginationAPIRequestRTK => {
  return {
    page: pagination.currentPage,
    pageSize: pagination.pageSize,
  }
}
