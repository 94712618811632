import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu as AntdMenu } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import {
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

import { useAuth } from '@breve/hooks/auth/useAuth'
import {
  PRIVATE_BASE,
  PRIVATE_COMPANIES,
  PRIVATE_EMPLOYEES,
  PRIVATE_EXAM_COMPONENTS,
  PRIVATE_EXAMS_FIELDS,
  PRIVATE_EXAMS_PROFILES,
  PRIVATE_EXAMS_SETTINGS,
  PRIVATE_ORDERS_COMPANY,
  PRIVATE_ORDERS_PATIENTS,
  PRIVATE_PATIENTS,
  PRIVATE_REVIEWS_EXAMS_RESULTS,
  PRIVATE_REVIEWS_ORDERS,
} from '@breve/routes/route-collection'

const Menu: FC = () => {
  const { user } = useAuth()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(location.pathname)

  useEffect(() => {
    setActiveKey(location.pathname)
  }, [location.pathname])

  const menuItems: ItemType[] =
    user && user.user.groups.find(group => group.name === 'ADMINISTRATOR')
      ? [
          {
            key: PRIVATE_BASE,
            icon: <HomeOutlined />,
            label: <Link to={PRIVATE_BASE}>Inicio</Link>,
          },
          {
            key: PRIVATE_COMPANIES,
            icon: <BuildingOffice2Icon className="max-w-[17px]" />,
            label: <Link to={PRIVATE_COMPANIES}>Compañías</Link>,
          },
          {
            key: PRIVATE_PATIENTS,
            icon: <UserGroupIcon className="max-w-[17px]" />,
            label: <Link to={PRIVATE_PATIENTS}>Pacientes</Link>,
          },
          {
            key: 'submenu-exams',
            icon: <ClipboardDocumentListIcon className="max-w-[18px]" />,
            label: 'Gestión de Exámenes',
            children: [
              {
                key: PRIVATE_EXAMS_FIELDS,
                label: (
                  <Link to={PRIVATE_EXAMS_FIELDS}>Campos de referencia</Link>
                ),
              },
              {
                key: PRIVATE_EXAMS_SETTINGS,
                label: (
                  <Link to={PRIVATE_EXAMS_SETTINGS}>
                    Formulario de exámenes
                  </Link>
                ),
              },
              {
                key: PRIVATE_EXAM_COMPONENTS,
                label: (
                  <Link to={PRIVATE_EXAM_COMPONENTS}>
                    Componentes de exámenes
                  </Link>
                ),
              },
              {
                key: PRIVATE_EXAMS_PROFILES,
                label: (
                  <Link to={PRIVATE_EXAMS_PROFILES}>Perfiles de exámenes</Link>
                ),
              },
            ],
          },
          {
            key: 'submenu-orders',
            icon: <ClipboardDocumentListIcon className="max-w-[18px]" />,
            label: 'Órdenes',
            children: [
              {
                key: PRIVATE_ORDERS_COMPANY,
                label: (
                  <Link to={PRIVATE_ORDERS_COMPANY}>Órdenes de Compañías</Link>
                ),
              },
              {
                key: PRIVATE_ORDERS_PATIENTS,
                label: (
                  <Link to={PRIVATE_ORDERS_PATIENTS}>Órdenes de Pacientes</Link>
                ),
              },
            ],
          },
          {
            key: 'submenu-reviews',
            icon: <ClipboardDocumentListIcon className="max-w-[18px]" />,
            label: 'Revisiones',
            children: [
              {
                key: PRIVATE_REVIEWS_ORDERS,
                label: (
                  <Link to={PRIVATE_REVIEWS_ORDERS}>Órdenes (General)</Link>
                ),
              },
              {
                key: PRIVATE_REVIEWS_EXAMS_RESULTS,
                label: (
                  <Link to={PRIVATE_REVIEWS_EXAMS_RESULTS}>
                    Resultado de Examenes
                  </Link>
                ),
              },
            ],
          },
          {
            key: PRIVATE_EMPLOYEES,
            icon: <UserOutlined />,
            label: <Link to={PRIVATE_EMPLOYEES}>Empleados (Usuarios)</Link>,
          },
        ]
      : [
          {
            key: PRIVATE_BASE,
            icon: <HomeOutlined />,
            label: <Link to={PRIVATE_BASE}>Inicio</Link>,
          },
          {
            key: PRIVATE_PATIENTS,
            icon: <UserGroupIcon className="max-w-[17px]" />,
            label: <Link to={PRIVATE_PATIENTS}>Pacientes</Link>,
          },
          {
            key: 'submenu-orders',
            icon: <ClipboardDocumentListIcon className="max-w-[18px]" />,
            label: 'Órdenes',
            children: [
              {
                key: PRIVATE_ORDERS_COMPANY,
                label: (
                  <Link to={PRIVATE_ORDERS_COMPANY}>Órdenes de Compañías</Link>
                ),
              },
              {
                key: PRIVATE_ORDERS_PATIENTS,
                label: (
                  <Link to={PRIVATE_ORDERS_PATIENTS}>Órdenes de Pacientes</Link>
                ),
              },
            ],
          },
          {
            key: 'submenu-reviews',
            icon: <ClipboardDocumentListIcon className="max-w-[18px]" />,
            label: 'Revisiones',
            children: [
              {
                key: PRIVATE_REVIEWS_ORDERS,
                label: (
                  <Link to={PRIVATE_REVIEWS_ORDERS}>Órdenes (General)</Link>
                ),
              },
              {
                key: PRIVATE_REVIEWS_EXAMS_RESULTS,
                label: (
                  <Link to={PRIVATE_REVIEWS_EXAMS_RESULTS}>
                    Resultado de Examenes
                  </Link>
                ),
              },
            ],
          },
        ]

  return (
    <AntdMenu
      className="SideMenu"
      defaultSelectedKeys={[activeKey]}
      mode="inline"
      theme="dark"
      activeKey={activeKey}
      selectedKeys={[activeKey]}
      items={menuItems}
    />
  )
}

export default Menu
