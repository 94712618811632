import type { VFC } from 'react'
import { useMemo } from 'react'
import classNames from 'classnames'
import { MenuOutlined } from '@ant-design/icons'

import logo from '@breve/assets/images/labsys/whiteLogo.ico'
import { useWindowSize } from '@breve/utils'

interface ResponsiveHeaderProps {
  handleOpen: () => void
}

const ResponsiveHeader: VFC<ResponsiveHeaderProps> = props => {
  const { handleOpen } = props
  const [width] = useWindowSize()

  const isDesktop = useMemo(() => width >= 992, [width])

  return (
    <div
      className={classNames(
        'responsive-header',
        !isDesktop ? 'flex' : 'hidden'
      )}
    >
      <div className="bg-white p-2 rounded-full">
        <img
          src={logo}
          alt="Labsys logo"
          className="w-5 h-5 rounded-md object-contain"
        />
      </div>
      <MenuOutlined
        className="text-lg text-white cursor-pointer"
        onClick={handleOpen}
      />
    </div>
  )
}

export default ResponsiveHeader
