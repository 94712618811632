import React, { useContext, useMemo, useState } from 'react'

export interface ConfigurationLayoutContextType {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const initialState: ConfigurationLayoutContextType = {
  title: '',
  setTitle: () => ({}),
}

export const ConfigurationLayoutContext =
  React.createContext<ConfigurationLayoutContextType>(initialState)

const ConfigurationLayoutProvider: React.FC = props => {
  const { children } = props
  const [title, setTitle] = useState('')

  const contextValue = useMemo<ConfigurationLayoutContextType>(
    () => ({
      title,
      setTitle,
    }),
    [title]
  )

  return (
    <ConfigurationLayoutContext.Provider value={contextValue}>
      {children}
    </ConfigurationLayoutContext.Provider>
  )
}

export const useConfigurationLayoutContext =
  (): ConfigurationLayoutContextType => useContext(ConfigurationLayoutContext)

export default ConfigurationLayoutProvider
