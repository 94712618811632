import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SystemAlertState {
  alertType?: 'success' | 'info' | 'warning' | 'error'
  alertMessage: string[]
  alertDescription?: string
  hasTextLoop?: boolean
  hasMarquee?: boolean
  hasIcon?: boolean
  isOpen: boolean
  hasAfterClose?: () => void
}

const initialState: SystemAlertState = {
  alertType: 'info',
  alertMessage: [''],
  alertDescription: '',
  hasTextLoop: false,
  hasMarquee: false,
  hasIcon: true,
  isOpen: false,
}

export const alertsSlice = createSlice({
  name: 'systemAlert',
  initialState,
  reducers: {
    showAlert(state, action: PayloadAction<SystemAlertState>) {
      Object.assign(state, action.payload)
    },
  },
  // clearError(state) {
  //   state.openDialog = false
  //   state.message = ''
  //   state.title = ''
  // },
})

export const { showAlert } = alertsSlice.actions

export default alertsSlice.reducer
