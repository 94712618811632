import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'

import type { ApiEnum } from './types/common.types'

export const enumsApi = createApi({
  reducerPath: 'enumsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/enums`,
  }),
  endpoints: builder => ({
    enumList: builder.query<ApiEnum[], string>({
      query: (enumType: string) => {
        return {
          url: `/${enumType}`,
          method: 'GET',
        }
      },
    }),
    fieldsDataTypeList: builder.query<ApiEnum[], void>({
      query: () => ({
        url: `/catalogs/fields/field-data-type`,
        method: 'GET',
      }),
      transformResponse: (response: Array<{ key: string; label: ApiEnum }>) => {
        return response
      },
    }),
    examCompareTypesList: builder.query<ApiEnum[], void>({
      query: () => ({
        url: `/catalogs/exams/exam-compare`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useEnumListQuery,
  useFieldsDataTypeListQuery,
  useExamCompareTypesListQuery,
} = enumsApi
