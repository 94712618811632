import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { removeCredentialsToken } from '@breve/utils/http.util'

import type { RootState } from './store'

export type UserGroup = {
  id: number
  name: 'ADMINISTRATOR' | 'OPERATOR'
}

export interface User {
  username: string
  isActive: true | false
  isStaff: true | false
  groups: UserGroup[]
}

export interface UserProfile {
  firstName: string
  firstSurname: string
  phone: string
  email: string
  sex: string
  workArea: string
  profilePhoto: string
  user: User
}

export interface ChangePasswordRequest {
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

export type AuthState = {
  user: UserProfile | null
  hasLoggedOut: boolean
}

export const defaultAuthState = {
  user: null,
  hasLoggedOut: false,
} as AuthState

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultAuthState,
  reducers: {
    setProfile: (state, { payload: user }: PayloadAction<UserProfile>) => {
      state.user = user
    },
    setIsLoggingOut: state => {
      state.hasLoggedOut = true
    },
    logout: state => {
      removeCredentialsToken()
      Object.assign(state, defaultAuthState)
      state.user = null
    },
  },
})

export const { setProfile, setIsLoggingOut, logout } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: RootState): UserProfile | null => {
  return state.auth.user
}

export const selectIsLoggingOut = (state: RootState): boolean => {
  return state.auth.hasLoggedOut
}
