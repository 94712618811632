import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { ExamComponentValues } from '@breve/validations/ExamComponentSchema'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type {
  ExamComponent,
  ExamComponentQueryParams,
} from './types/exam-components.types'

export const examComponentsApi = createApi({
  reducerPath: 'examComponentsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/catalogs/components`,
  }),
  tagTypes: ['ExamComponents'],
  endpoints: builder => ({
    examComponentsList: builder.query<
      CommonPaginationAPIResults<ExamComponent>,
      CommonPaginationAPIRequest<ExamComponentQueryParams> | void
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'ExamComponents', id: 'COMPONENT_LIST' }],
    }),
    examComponentsDetail: builder.query<ExamComponent, number>({
      query: (examComponentId: number) => ({
        url: `/${examComponentId}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ExamComponents', id: 'DETAILS' }],
    }),
    createExamComponent: builder.mutation<ExamComponent, ExamComponentValues>({
      query: newExamComponent => ({
        url: '/',
        method: 'POST',
        data: newExamComponent,
      }),
      invalidatesTags: [
        { type: 'ExamComponents', id: 'COMPONENT_LIST' },
        { type: 'ExamComponents', id: 'DETAILS' },
      ],
    }),
    updateExamComponent: builder.mutation<
      ExamComponent,
      CommonUpdateRequest<ExamComponentValues>
    >({
      query: ({
        EntityToUpdate: updatedExamComponent,
        EntityIdToUpdate: examComponentToUpdateId,
      }) => ({
        url: `/${examComponentToUpdateId}/`,
        method: 'PUT',
        data: updatedExamComponent,
      }),
    }),
    deleteExamComponent: builder.mutation<
      CommonDeleteResponse,
      ExamComponent['id']
    >({
      query: examComponentId => ({
        url: `/${examComponentId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ExamComponents', id: 'COMPONENT_LIST' },
        { type: 'ExamComponents', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useExamComponentsListQuery,
  useLazyExamComponentsListQuery,
  useExamComponentsDetailQuery,
  useCreateExamComponentMutation,
  useUpdateExamComponentMutation,
  useDeleteExamComponentMutation,
} = examComponentsApi
